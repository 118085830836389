import { host } from "./index"
import axios from "axios";

export const getCampaignAds = async (deviceId, clientId, appVersion) => {

    let request = {
        method: 'get',
        url: `${host}/v1/superApps/ads/list?deviceId=${deviceId}&clientId=${clientId}&appVersion=${appVersion}`,
    };

    return axios(request);
}