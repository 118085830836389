import { host } from "./index"
import axios from "axios";

export let fetchCategoriesList = async () => {
    let request = {
        method: "GET",
        url: `${host}/v2/superApps/categories/list`
    }
    return axios(request);

}

export let fetchTopProductsByCategory = async (userId, deviceId) => {
    let url = `${host}/v2/superApps/categories`;
    if(deviceId != null){
        url = url + "?deviceId=" + deviceId
    }
    if(userId != null && deviceId != null){
        url = url + "&userId=" + userId
    } else if(userId != null){
        url = url + "?userId=" + userId
    }
    let request = {
        method: "GET",
        url: url
    }
    return axios(request);

}

