import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from 'uuid';
import { freeDeliveryTagGIf, moneyGif, memeChatCategoryIcon } from "../../config/icons";
import { isExploreCategoriesEnable, env } from "../../config/index";
import { WebViewClientId } from "../../config/index.js";
import { fetchCarts, getAllCarts } from "../../store/slices/cart";
import { setShowBottomFixedBar } from "../../store/slices/footer";
import { setFocusOnSearchBar } from "../../store/slices/global";
import { resetHeaderState, setHomePageHeaderState, setSearchBarHeaderState, setShowTrendingSearchesAndHistory, setShowcategoriesContainer } from "../../store/slices/header";
import { fetchAddresses } from "../../store/slices/userAddress";
import { fetchSuperAppsList } from "../../util/apiUtils";
import { getCampaignAds } from "../../util/apiUtils/ads";
import { fetchTopProductsByCategory } from "../../util/apiUtils/categories";
import { getTopDealProducts } from "../../util/apiUtils/product";
import { openDeepLink } from "../../util/actions/interfaces.js";

import { getValidObjectKeyFromString, isInViewPortHomePage } from "../../util/common/index";
import { showToast, toastTypes } from "../../util/common/toasts";
import { noNetworkErrorCode, noNetworkErrorText, unexpectedErrorText } from "../../util/errors";
import { ActionsTypes, CategoryBannerClickedEvent, ProductListingCategoryClickedEvent, ProductListingSearchClickedEvent, SearchIconScreens, ServiceTypes, SuperAppErrorOccured, SuperAppWebLoaderTimeEvent, dispatchEventToNative } from "../../util/events";
import { retrieveFromSessionStorage, storeInSessionStorage } from "../../util/storageUtils/sessionStorageUtils";
import { restoreScrollPos } from "../../components/UseScrollRestoration";
const Services = dynamic(() => import("../../components/Services"))
const TopDealsSection = dynamic(() => import("../../components/TopDealsSection"))
const BottomFixedBar = dynamic(() => import("../../components/BottomFixedBar/BottomFixedBar"))
const SectionBreak = dynamic(() => import("../../components/common/SectionBreak"))
const ExploreByCategories = dynamic(() => import("../../components/ExploreByCategories/ExploreByCategories"))
const ProductNotFound = dynamic(() => import("../../components/Home/ProductNotFound"))
const HorizontalOffersCarousel = dynamic(() => import("../../components/HorizontalOffersCarousel"))
const VerticalCampaignTile = dynamic(() => import("../../components/VerticalCampaignTile"))

const ProductQuickPreviewContainer = dynamic(() => import("../../components/ProductQuickPreviewContainer"))
const ProductHeader = dynamic(() => import("../../components/ProductsHeader"))
const SearchBar = dynamic(() => import("../../components/SearchBar/SearchBar"))
const SpinnerLoader = dynamic(() => import("../../components/SpinnerLoader"))
const SubCategoryCarousel = dynamic(() => import("../../components/SubCategoryCarousel/SubCategoryCarousel"))
const Layout = dynamic(() => import("../../layouts/Layout"))

const filterCampaignAds = (campaignAdsRes, installedApps) => {
    let _campaignAds = campaignAdsRes?.filter((campaignAd) => {
        if (campaignAd.packageFilters?.length === 0) return true;
        let _packageFilter = campaignAd?.packageFilters?.filter((packageFilter) => {
            if (packageFilter?.required) {
                return installedApps?.join("")?.includes(packageFilter?.name)
            }
            if (packageFilter?.required === false) {
                return true;
            }
        })
        return _packageFilter.length > 0
    })
    return _campaignAds;
}

export default function Home() {
    const router = useRouter()
    const [categories, setCategories] = useState([]);
    const [topDealProducts, setTopDealProducts] = useState(null)
    const [isLoading, setIsLoading] = useState(true);
    const [showSearchBarOnHeader, setShowSearchBarOnHeader] = useState(false);
    const [loaderId, setLoaderId] = useState(uuidv4());
    const [campaignAds, setCampaignAds] = useState([])
    const [superApps, setSuperApps] = useState(null)
    const dispatch = useDispatch();
    const clientData = useSelector(state => state.user?.clientData);
    const installedApps = useSelector(state => state.user?.installedApps);
    const appVersion = useSelector(state => state?.user?.clientData?.appVersion);
    const userId = useSelector(state => state?.user?.data?.userId);
    const accessToken = useSelector(state => state?.user?.authData?.accessToken);
    const dpandaAuthtoken = useSelector(state => state?.user?.data?.dpandaAuthtoken);
    const deviceId = useSelector(state => state?.user?.clientData?.deviceId);
    const screenName = useSelector(state => state?.user?.clientData?.screenName);
    const isDeviceRegistered = useSelector(state => state?.user?.isDeviceRegistered);

    useEffect(() => {
        if (!retrieveFromSessionStorage("utmSource")) {
            storeInSessionStorage("utmSource", JSON.stringify("Store"))
        }
        storeInSessionStorage("baseScreen", "Store")
    }, [])

    // useEffect to set header state values in global state upon page loading
    useEffect(() => {
        dispatch(resetHeaderState());
        dispatch(setSearchBarHeaderState());
        showToast("DEV Environment", toastTypes.info, env.DEV)
    }, [dispatch, router]);

    //handles the header to be shown on user scroll 
    useEffect(() => {
        if (showSearchBarOnHeader) {
            dispatch(resetHeaderState());
            dispatch(setSearchBarHeaderState());
            dispatch(setShowcategoriesContainer(true))
            dispatch(setShowTrendingSearchesAndHistory(true));
        } else {
            dispatch(resetHeaderState());
            dispatch(setHomePageHeaderState());
            dispatch(setShowcategoriesContainer(false))
            dispatch(setShowTrendingSearchesAndHistory(false));
        }

    }, [dispatch, showSearchBarOnHeader])

    /*global declaration to avoid eslint error of undefined, Promise*/
    useEffect(() => {
        const promiseGetTopProductsByCategory = async () => {
            if (retrieveFromSessionStorage("topProductsByCategory")) {
                setCategories(JSON.parse(retrieveFromSessionStorage("topProductsByCategory")));
                return
            }
            try {
                let response = await fetchTopProductsByCategory(userId, deviceId);
                if (response?.data?.categories?.length > 0) {
                    setCategories(response?.data?.categories);
                    storeInSessionStorage("topProductsByCategory", JSON.stringify(response?.data?.categories));
                }
            } catch (err) {
                console.log(err)
                let eventLabel = {}
                eventLabel["service_type"] = ServiceTypes.productListing
                eventLabel["action_type"] = ActionsTypes.viewed
                eventLabel["error_message"] = err?.response?.data?.errorDescription
                eventLabel["error_code"] = err?.response?.data?.errorCode
                dispatchEventToNative(screenName, SuperAppErrorOccured, eventLabel);
                err?.code === noNetworkErrorCode ? showToast(noNetworkErrorText, toastTypes.error) :
                    showToast(unexpectedErrorText, toastTypes.error)
            }
        }

        const promiseGetSuperAppsListPromise = async () => {
            if (retrieveFromSessionStorage("superAppsList")) {
                console.log("SuperAppsList from Session Storage", JSON.parse(retrieveFromSessionStorage("superAppsList")))
                setSuperApps(JSON.parse(retrieveFromSessionStorage("superAppsList")));
                return;
            }
            try {
                let response = await fetchSuperAppsList(clientData?.deviceId, WebViewClientId, appVersion);
                console.log("SuperApps Response", response)
                if (response?.data?.apps?.length > 0) {
                    console.log("SuperApps List", response?.data)
                    setSuperApps(response?.data);
                    storeInSessionStorage("superAppsList", JSON.stringify(response?.data));
                }
            } catch (error) {
                console.log(error)
                let eventLabel = {}
                eventLabel["service_type"] = ServiceTypes.partnerService
                eventLabel["action_type"] = ActionsTypes.viewed
                eventLabel["error_message"] = error?.response?.data?.errorDescription ?? error
                eventLabel["error_code"] = error?.response?.data?.errorCode ?? error
                dispatchEventToNative(screenName, SuperAppErrorOccured, eventLabel);
                error?.code === noNetworkErrorCode ? showToast(noNetworkErrorText, toastTypes.error) :
                    showToast(unexpectedErrorText, toastTypes.error)
            }
        }
        
        const promiseGetCampaignAds = async () => {
            if (!(clientData?.deviceId && clientData?.clientId)) return;
            if (retrieveFromSessionStorage("campaignAds")) {
              let _campaignAds = JSON.parse(retrieveFromSessionStorage("campaignAds"));
              setCampaignAds(filterCampaignAds(_campaignAds, installedApps));
              return;
            }
            try {
              let response = await getCampaignAds(clientData.deviceId, clientData?.clientId, appVersion);
              if (response && response?.data?.ads?.length > 0) {
                const sortedCampaignAds = response.data.ads.sort((a, b) => b.priority - a.priority);
                setCampaignAds(filterCampaignAds(sortedCampaignAds, installedApps));
                storeInSessionStorage("campaignAds", JSON.stringify(sortedCampaignAds));
              }
            } catch (error) {
              // Handle the error appropriately
              console.error("Error fetching campaign ads:", error);
              console.log(err)
                let eventLabel = {}
                eventLabel["service_type"] = ServiceTypes.specialOffers
                eventLabel["action_type"] = ActionsTypes.viewed
                eventLabel["error_message"] = err?.response?.data?.errorDescription
                eventLabel["error_code"] = err?.response?.data?.errorCode
                dispatchEventToNative(screenName, SuperAppErrorOccured, eventLabel);
                err?.code === noNetworkErrorCode ? showToast(noNetworkErrorText, toastTypes.error) :
                    showToast(unexpectedErrorText, toastTypes.error)
            }
          };

        const promiseGetTopDealProducts = async () => {
            if (retrieveFromSessionStorage("topDealProducts")) {
                setTopDealProducts(JSON.parse(retrieveFromSessionStorage("topDealProducts")));
                return
            }
            try {
                let response = await getTopDealProducts(userId, deviceId);
                console.log({ response });
                if (response?.data?.products?.length > 0) {
                    setTopDealProducts(response?.data);
                    storeInSessionStorage("topDealProducts", JSON.stringify(response?.data));
                }
            } catch (err) {
                console.log(err)
                let eventLabel = {}
            }
        }

        if (clientData?.deviceId?.length > 0 && isDeviceRegistered) {
            Promise.all([promiseGetTopProductsByCategory(), promiseGetSuperAppsListPromise(), promiseGetCampaignAds(), promiseGetTopDealProducts()]).finally(() => {
                setIsLoading(false)
            })
        }
    }, [clientData?.deviceId, clientData?.clientId, installedApps, isDeviceRegistered])

    //handles serach bar position and on scroll position
    useEffect(() => {
        // enable bottom fixed bar for home screen
        setShowBottomFixedBar(true)

        const onScroll = () => {
            // Ignoring scroll event if search-bar is in focus
            if (document.activeElement.className === "search-bar") return

            let homePageSearchBar = document.getElementById("search-bar-container");
            let isHomePageSearchBarInViewPort = isInViewPortHomePage(homePageSearchBar);
            let homeDiv = document.getElementById("home-page")
            const homeDivClassList = ["sticky-search-bar"]
            if (isHomePageSearchBarInViewPort) {
                if (homeDiv) {
                    homeDiv.classList.add(...homeDivClassList)
                }
                return;
            }
            if (homeDiv) {
                homeDiv.classList.remove(...homeDivClassList)
            }
        }

        window.addEventListener(
            "scroll",
            onScroll,
            false
        );

        return () => {
            window.removeEventListener("scroll", onScroll);
        };
    }, []);

    // TODO: update name
    const onSearchBarFocus = (e) => {
        setShowSearchBarOnHeader(true);
        dispatch(setFocusOnSearchBar(true));
        let header = document.getElementById("header")
        if (header) {
            const headerClassList = ["searchBar-focused"]
            header.classList.add(...headerClassList)
        }
        const input = document.getElementById("search-bar")
        if (input) {
            input.focus();
        }

        let eventLabel = {}
        eventLabel["flow"] = SearchIconScreens[router?.pathname]
        dispatchEventToNative(screenName, ProductListingSearchClickedEvent, eventLabel)
    }

    const onBackButtonClick = () => {
        setShowSearchBarOnHeader(false);
        let header = document.getElementById("header")
        let homeDiv = document.getElementById("home-page")
        const homeDivClassList = ["sticky-search-bar"]
        if (header) {
            const headerClassList = ["searchBar-focused"]
            header.classList.remove(...headerClassList)
        }
        if (homeDiv) {
            homeDiv.classList.remove(...homeDivClassList)
        }
    }

    useEffect(() => {
        dispatch(fetchAddresses({ accessToken, deviceId }));
        if (userId && (accessToken || dpandaAuthtoken)) {
            dispatch(fetchCarts({ userId, accessToken, dpandaAuthtoken }))
        }
        dispatch(getAllCarts())
    }, [userId, accessToken, dpandaAuthtoken])

    useEffect(() => {
        if (!retrieveFromSessionStorage("isHomeScreenLoadedOnce")) {
            let eventLabel = {};
            if (isLoading) {
                let beforeLoadingHomePageTime = new Date().getTime();
                eventLabel["webview_url"] = router.asPath;
                eventLabel["timestamp"] = beforeLoadingHomePageTime;
                eventLabel["action"] = "loader_start";
                eventLabel["loader_id"] = loaderId;
                eventLabel["flow"] = "superapp_icon";
            } else {
                let afterLoadingHomePageTime = new Date().getTime();
                eventLabel["webview_url"] = router.asPath;
                eventLabel["timestamp"] = afterLoadingHomePageTime;
                eventLabel["action"] = "loader_finish";
                eventLabel["loader_id"] = loaderId;
                eventLabel["flow"] = "superapp_icon";
                storeInSessionStorage("isHomeScreenLoadedOnce", true);
            }
            dispatchEventToNative(screenName, SuperAppWebLoaderTimeEvent, eventLabel);
        }
        if (!isLoading) {
            restoreScrollPos("/bobblexclusive/home")
        }
    }, [isLoading]);

    return (
        <div id="home-page">
            <Layout
                onBackButtonClick={onBackButtonClick}
            >
                {
                    isLoading ?
                        <div className="skeleton-container">
                            <SpinnerLoader type={"home"} />
                        </div> :
                        <Fragment>
                            {/* <div id="home-search-bar" className="search-bar-container search-home">
                                <SearchBar onSearchBarFocus={onSearchBarFocus} />
                            </div> */}
                            
                            {/* Top Deals Section */}
                            {/* {topDealProducts && topDealProducts?.products?.length >= 2 && (
                                <TopDealsSection topDealProducts={topDealProducts} />
                            )} */}
                        
                          {/* Campaign Ads */}
                            {campaignAds.length > 0 && (
                                <Fragment>
                                    {campaignAds.map((item) => (
                                        <VerticalCampaignTile
                                            key={item.id}
                                            campaign={{
                                                bannerURL: item.bannerUrl,
                                                name: `Campaign ${item.id}`,
                                                id: item.id,
                                                redirectURL: item.actions[0]?.url, // Add the redirectURL property
                            
                                            }}
                                            onCampaignClick={() => {
                                                // Handle the click action here
                                                if (item.actions && item.actions.length > 0) {
                                                  const firstAction = item.actions[0];
                                                  if (firstAction.type === "deeplink") {
                                                    // Check if a valid URL is present in the action
                                                    if (firstAction.url) {
                                                      // Call the openDeepLink function with deepLinkUrl and appName
                                                      openDeepLink(firstAction.url);
                                                    } else {
                                                      // Handle the case when no URL is defined in the action
                                                      console.log("No URL defined for this deeplink action.");
                                                    }
                                                  } else {
                                                    // Handle the click action for other types
                                                    window.open(item.actions[0]?.url, '_blank', 'noopener noreferrer');
                                                  }
                                                } else {
                                                  // Handle the case when there are no actions defined for the item
                                                  console.log("No actions defined for this campaign.");
                                                }
                                              }}
                                              
                                        />
                                    ))}
                                </Fragment>
                            )}

                            {/* Services
                            <Services
                                superAppsList={superApps}
                                installedApps={installedApps}
                            />
                             */}
                            <SectionBreak height={"0.5rem"} />
                            {/* <ProductNotFound /> */}
                        </Fragment>
                }
            </Layout>
            {!(isLoading) && <BottomFixedBar />}
        </div>
    );
}
