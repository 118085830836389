
export const openDeepLink = (deepLinkUrl) => { 
    const app = "superapp"; // Hardcoding "superapp"

    console.log("openDeepLink", deepLinkUrl, app);

    if (window && window.Android && window.Android.openDeepLink) {
        console.log("openDeepLink", deepLinkUrl, app);
        window.Android.openDeepLink(deepLinkUrl, JSON.stringify(app));
    }
}